/* website: 4166-langleymazda
 * created at 2023-11-21 15:00 by Mfaye
 */
// Import all makes and organization styles files
@import "../templates/makes/mazda.scss";
@import "../utils/icons.scss";
.widget-sr{
  &.dealer__wolfeslangleymazda-358{
    .showroom-vdp-used,
    .showroom-vdp-new{
      .btn-get-started{
        &.vehicle-cta-btn{
          display: none;
        }
      }
    }
  }
}